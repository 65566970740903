<template>
	<div class="pa-0 px-4 wrapper">
		<v-container
			class="pa-0"
			fluid
		>
			<!-- 타이틀 -->
			<v-col
				align="center"
				class="pa-0"
			>
				<v-row class="ma-0 py-5 justify-center main_tit">
					<v-col
						class="pa-0"
						cols="auto"
						align-self="center"
					>
						<v-img
							width="24"
							eager
							:src="require('@/assets/img/careflo/icon_care_flo.svg')"
						/>
					</v-col>
					<v-col
						class="pa-0 ml-1"
						align-self="center"
						cols="auto"
					>
						케어플로 서비스
					</v-col>
				</v-row>
			</v-col>

			<!-- 구매자 접속정보 -->
			<v-col
				cols="12"
				class="pa-5 section layout_align"
			>
				<h3 class="mb-2">구매자 인증 접속</h3>
				<p class="desc mb-4">최초 접속시 입력했던 이름과 휴대폰 번호를 입력해주세요</p>

				<!-- 이름 -->
				<p class="mb-2 item">이름</p>
				<CommonInputsInput05
					v-model="name"
					class="mb-4"
					:height="40"
					placeholder="이름"
				/>

				<!-- 휴대폰번호 -->
				<p class="mb-2 item">휴대폰번호</p>
				<v-row class="ma-0 mb-2">
					<v-col
						class="pa-0"
						cols="8"
					>
						<div class="mr-1">
							<CommonInputsInput05
								v-model="hand"
								rule-name="tel"
								:height="40"
								type="number"
								placeholder="휴대폰번호 (-없이 입력)"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
					>
						<CommonButtonsButton01
							class="pa-0 btn_confirm"
							:disabled="hand === null"
							name="인증번호 발송"
							:color="smsNumberCheck ? 'primary' : '#262626'"
							:height="40"
							@click="checkSms(sms_check_type[12])"
						/>
						<v-col
							cols="12"
							class="pa-0 pr-1"
							align="end"
						>
							<p
								v-if="smsNumberCheck"
								style="font-size: 12px; color: #00ce7d"
							>
								인증되었습니다.
							</p>
						</v-col>
					</v-col>
				</v-row>
				<v-row
					v-if="smsNumberModel"
					class="ma-0"
				>
					<v-col
						class="pa-0"
						cols="8"
					>
						<div class="mr-1">
							<CommonInputsInput05
								v-model="smsNum"
								:height="40"
								type="number"
								placeholder="인증번호 입력"
							/>
						</div>
					</v-col>
					<v-col
						class="pa-0"
						cols="4"
					>
						<CommonButtonsButton01
							class="btn_confirm"
							name="인증확인"
							color="#262626"
							:height="40"
							@click="confirmSmsNumber"
						/>
						<v-col
							cols="12"
							class="pa-0 pr-2 pt-1"
							align="end"
							style="color: #fa2a6f; font-size: 12px"
						>
							{{ sms_check_timer }}
						</v-col>
					</v-col>
					<CommonLoaderLine01
						v-if="telLoading"
						class="mt-1"
					/>
				</v-row>

				<CommonButtonsButton01
					class="mt-5 btn_clear"
					name="접속"
					color="#78C046"
					:disabled="!smsNumberCheck"
					:height="42"
					@click="confirm"
				/>
			</v-col>
		</v-container>
	</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import model from '@/mixins/model'
import sms from '@/mixins/sms'
export default {
	name: 'CareFloCustomerLogin',
	mixins: [model, sms],
	data: () => ({
		name: null,
		hand: null,
		smsNum: null,

		// sms
		smsNumberCheck: false,
		smsNumberModel: false,
		telLoading: false,
	}),
	computed: { ...mapGetters(['CAREFLO_GET_AUTH_USER']) },
	watch: {
		sms_check_timer(val) {
			if (val === '00:00') {
				this.smsNumberCheck = false
				this.smsNumberModel = false
			}
		},
	},
	mounted() {},
	methods: {
		...mapMutations(['CAREFLO_MU_AUTH_USER']),
		...mapActions(['CAREFLO_ACT_CAREFLO_NFC_CODE']),
		async checkSms(type) {
			this.telLoading = true
			let params = {
				type: type,
				hand: this.hand,
				name: this.name,
				login_id: '',
			}
			const check = await this.sms_call(params)
			if (check) {
				this.telLoading = false
				this.smsNumberModel = true
			}
		},
		async confirmSmsNumber() {
			const check = await this.sms_call_check(this.hand, this.smsNum)
			if (check) {
				this.smsNumberCheck = true
				this.smsNumberModel = false
			}
		},
		confirm() {
			if (!this.smsNumberCheck) return
			const item = {
				customer: true,
				hand: this.hand,
				name: this.name,
			}
			this.getList(item)
		},

		async getList(item) {
			const params = {
				nfc_code: this.CAREFLO_GET_AUTH_USER.nfc_code,
				cust_hand: item.hand,
				cust_name: item.name,
			}
			const items = { method: 'detail', params: params }
			await this.CAREFLO_ACT_CAREFLO_NFC_CODE(items).then(res => {
				console.log('CAREFLO_ACT_CAREFLO_NFC_CODE', res)
				// as정보가 있을경우만 다음 페이지로 이동
				if (Object.keys(res).length > 0) {
					this.CAREFLO_MU_AUTH_USER(item)
					this.$router.push(`/careflo/customer/asInfo`)
				}
			})
		},
	},
}
</script>

<style scoped lang="scss">
.main_tit {
	font-size: $font_xl;
	font-weight: $fw_bold;
	line-height: 1;
	color: $color_font;
}
.sub_tit {
	font-size: $font_normal;
	color: $color_gray_7;
}

.section {
	max-width: 400px;
	background-color: $color_white;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
	border-radius: 16px;
	h3 {
		color: $color_font;
		font-size: $font_lg;
		font-weight: $fw_bold;
	}
	.desc {
		font-size: $font_normal;
		color: $color_gray_5;
		word-break: keep-all;
	}
	.item {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}

.checkbox_group {
	min-height: 180px;
	border-radius: 8px;
	background-color: $color_gray_1;
}

.btn_clear {
	:deep(.v-btn__content) {
		color: $color_white;
		font-weight: $fw_bold;
		font-size: $font_lg;
	}
}

.btn_confirm {
	:deep(.v-btn__content) {
		font-size: $font_normal !important;
	}
}

.center_info {
	.txt_1 {
		font-size: $font_normal;
		color: $color_gray_6;
	}
	.txt_2 {
		font-size: $font_lg;
		font-weight: $fw_bold;
		color: $color_gray_8;
	}
	.txt_3 {
		font-size: $font_normal;
		color: $color_gray_8;
	}
}
</style>
